@use '../mixins'

.navbar
    display: flex
    box-shadow: rgba(0, 0, 0, .15) 0px 2px 10px 0
    justify-content: space-between
    align-items: center
    padding: .5em 2em

.brand
    font-size: 1.2em
    & span
        font-weight: 900
        text-transform: uppercase

.links_list
    display: flex
    list-style: none
    flex-wrap: wrap
    & li
        margin-right: 1em
        @include mixins.tablet
            margin: 0 0 1em
        & a
            padding: .4em .6em

.active
    background-color: #000
    color: #fff
