@use '../mixins'

.footer
    height: 250px
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    background-color: #edf3f6
    @include mixins.mobile
        padding: 0 2em
