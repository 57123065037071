.search_container
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    & h2
        margin-bottom: 1em
    & p
        margin-bottom: 30px

.noposts
    text-align: center
