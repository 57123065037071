body
    background-color: #f6fcff
    padding: 0
    margin: 0
    font-family: 'Montserrat', sans-serif

input, textarea
    font-family: 'Montserrat', sans-serif
    resize: none
    &::placeholder
        font-family: 'Montserrat', sans-serif
        color: #aaa
        font-size: 14px

// The original CSS removed the outline. For accessibility purposes, I will not use this
// input:focus, textarea:focus
//     outline: none

a, nav button
    text-decoration: none
    background-color: transparent
    border: none
    color: #000
    transition: .4s
    font-size: 1em
    cursor: pointer
    &:hover
        color: #bbb

// Forms styles
form
    max-width: 40%
    min-width: 300px
    margin: 0 auto

label
    display: flex
    flex-direction: column
    font-weight: bold
    text-align: left
    margin-bottom: 1em

input, textarea
    box-sizing: border-box
    border: none
    border-bottom: 1px solid #ccc
    padding: .8em 0
    background-color: transparent

.btn
    background-color: #1a8918
    color: #fff
    text-align: center
    cursor: pointer
    border-radius: 10px
    width: 120px
    font-weight: bold
    border: none
    padding: 10px 15px
    font-size: 1em
    transition: .4s
    &.btn-dark
        background-color: #000
        border-radius: 0
    &.btn-outline
        background-color: transparent
        border-radius: 0
        color: #000
        border: 1px solid #000
        padding: 7px 30px
        &:hover
            background-color: #000
            color: #fff
    &:hover
        background-color: #0f730c
        color: #000
    &.btn-danger:hover
        background-color: #dc3545

button[disabled]
    background-color: #aaa

.container .error
    color: #721c24
    background-color: #f8d7da
    border: 1px solid #f5c6cb
    padding: 5px
    border-radius: 5px
