.about
    text-align: center
    display: flex
    flex-direction: column
    align-items: center
    & p
        color: #aaa
        margin-bottom: 2em
    & a
        display: block
        margin-top: 15px
        padding: 10px 15px
