@use '../../mixins'

.dashboard
    text-align: center
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    & h2
        font-size: 2.2em
        margin-bottom: .5em
    & p
        color: #aaa
        margin-bottom: 1em

.noposts
    text-align: center
    & p
        margin-bottom: 1.5em
    & a
        padding: 10px 25px

.post_header, .post_row
    display: flex
    justify-content: space-between
    width: 80%
    padding: 10px
    @include mixins.tablet
        gap: 2em

.post_header
    font-weight: bold
    border-bottom: 2px solid #ccc

.post_row
    align-items: center
    border-bottom: 1px solid #eee
    & p
        color: #000
    & button, & a
        margin: 0 5px
        height: 30px
        width: 100px
        font-size: .7em
        @include mixins.tablet
            display: flex
            flex-direction: column
            height: auto
            width: auto
            margin: 5px 0
