@use '../mixins'

.post_detail
    width: 100%
    max-width: 600px
    margin-bottom: 2em
    & img
        width: 100%
        max-width: 600px
    & h2
        margin-bottom: .4em
    & .tags
        margin-bottom: 1.2em
        display: flex
        flex-wrap: wrap
        & p
            margin-right: .5em
        & span
            font-weight: bold

.createdby
    font-style: italic
    color: #444
    font-size: .8em
    margin-bottom: 1em
