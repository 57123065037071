.edit_post
    text-align: center
    & h2
        font-size: 2.2em
    & p
        color: #aaa
        margin-bottom: 2em
    & .preview_title
        margin-bottom: 2em
        color: #000
        font-weight: bold

.image_preview
    max-width: 100%
    margin-bottom: 1em
